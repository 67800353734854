import animationsLoader from '../preloaders/animations/animationsLoader';
import eAnimationType from '../preloaders/animations/eAnimationType';
import eCellAnimation from './cell/eCellAnimation';
import { store, storeObserver } from '../index';
import selectors from '../redux/game/selectors';
import { eGameState } from '../redux/game/reducer';
import ControllerSounds from './controllerSounds';
import eSoundsType from '../preloaders/sounds/eSoundsType';
import { MAIN_TANK_INDEX } from '../constants';

export default class ControllerScene {
  constructor(container) {
    this.mainTankAnimation = animationsLoader.createAnimation(eAnimationType.EAT_CELL);
    this.mainTankAnimation.x = 540;
    this.mainTankAnimation.y = 665;

    this.mainTankAnimation.state.setAnimation(0, eCellAnimation.ECA_TANK_IDLE, true);

    container.addChildAt(this.mainTankAnimation, MAIN_TANK_INDEX);

    this.cells = [];
    for (let i = 0; i < 9; i++) {
      const cell = container.getChildByName(`cell_${i}`);
      cell.onCompleteAnim.add(this.onCellAnimationCompleted);
      this.cells.push(cell);
    }

    this.comletedAnimations = {};
    this.indexForKillAnimations = [];

    storeObserver.addListener(selectors.getGameState, this.onGameStateChanged);
  }

  onCellAnimationCompleted = async (animation, index) => {
    if (animation === eCellAnimation.ECA_IDLE_BUSH || animation === eCellAnimation.ECA_IDLE_ROCK || this.animationStarted) return;
    this.comletedAnimations[index] = animation;

    if (animation === eCellAnimation.ECA_KILLED)
      this.indexForKillAnimations.push(index);

    const state = store.getState();
    const isWin = selectors.getIsWin(state);
    if (isWin) return;

    if (Object.keys(this.comletedAnimations).length === 9) {
      this.animationStarted = true;
      const index = this.indexForKillAnimations.length;
      for (let i = 0; i < index; i++) {
        const item = this.indexForKillAnimations[i];
        ControllerSounds.playSound(eSoundsType.EST_SHOT);
        await this.cells[item].showAnimation(eCellAnimation.ECA_KILLED);
        if (this.indexForKillAnimations.length === 0) break; // animation skipped
      }

      if (this.indexForKillAnimations.length === 0) return; // animation skipped

      ControllerSounds.playSound(eSoundsType.EST_EXPLOSION);
      setTimeout(() => ControllerSounds.playSound(eSoundsType.EST_LOSE), 100);
      this.mainTankAnimation.state.setAnimation(0, eCellAnimation.ECA_TANK_DESTROYED_START, false);
      this.mainTankAnimation.state.addAnimation(0, eCellAnimation.ECA_TANK_DESTROYED_IDLE, true, 0);
      this.comletedAnimations = {};
      this.indexForKillAnimations = [];
    }
  }

  onGameStateChanged = () => {
    const state = store.getState();
    const gameState = selectors.getGameState(state);
    if (gameState === eGameState.EGS_CAN_CANCEL) {
      this.animationStarted = false;
      this.indexForKillAnimations = [];
      this.comletedAnimations = {};
      this.mainTankAnimation.state.setAnimation(0, eCellAnimation.ECA_TANK_IDLE, false);
    }
  }
}
